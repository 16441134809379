@use "src/_btn.scss";
@use "src/_theme.scss";
@use "src/_controls.scss";
@use "src/_inv.scss";
@use "src/_store.scss";
@use "src/_utils.scss";
@use "src/_bf.scss";

@use 'sass:color';

$red: #FF4A4A;
$rorange: #FF6A4D;
$orange: #FF9052;
$yorange: #FFB655;
$yellow: #FFD757;
$y2: #f0b132;
$y3: color.adjust($yellow, $saturation: -20%);
$y4: #efd035;
$green: #6bffc2;

$green2: #00e17a;

$bp-small-w: 375px;
$bp-small-h: 667px;

$unity: $y2;

$accentColor: white;
//$hoverColor: $orange;
$hoverColor: $unity;
body {
  @include theme.textFont;
  background: black;
}
strong, button, input {
  @include theme.altFont;
}

@include btn.Btn;
@include controls.Controls;
@include inv.Inv;
@include store.Store;
body {
  color: white;
}
button.mmbtn {
  position: fixed;
  top: 16px;
  right: 16px;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  background: black;
  &:hover {
    background: black;
  }
}

$h: 96px;
.header {
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  &__img {
    img {
      height: $h;
    }
    //display: inline-block;
    display: none;
     vertical-align: middle;
  } 
  &__text {
    height: $h;
    font-size: $h/4;
    display: inline-block;
     vertical-align: middle;
     line-height: $h;
  }
  border-bottom: 1px solid white;
  box-sizing: border-box;
  background: black;

}
.core {
  position: fixed;
  top: $h;
  left: 0;
  padding: 16px;
  bottom: 0; 
  right: 0;
}


$g: 16px;
.familiars {
  padding: $g;
}

$fw: 256px;
.familiar {
  display: inline-block;
  border: 1px solid white;
  border-radius: 4px;
  padding: $g;
  margin: $g;
  &__img {
    img {
      width: 90%;
      max-width: 640px;
      min-width: 50%;
    }
  }
  &__text {

  }
}

.transfer {
  margin-top: $g;
  input {
    border: none;
    border-bottom: 1px solid white;
    background: black;
    color: white;
    &:focus {
      outline: none;
      border-bottom: 1px solid $red;
    }
  }
}


button + button {
  margin-left: $g;
}

.core {
  /*overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }



&::-webkit-scrollbar-track {
  background: #f1f1f1;
}


&::-webkit-scrollbar-thumb {
  background: $rorange;
}


&::-webkit-scrollbar-thumb:hover {
  background: #555;
}
*/

}

.airdrop2Manager {
  ul {
    color: white;
    li {
      color: white;
    }
  }
  
}



p {
  margin: 0;
}


.header--false, .core--false {
  display: none;
}




button.mmbtn.mmbtn--false {
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: 16px;
}

button.mmbtn.mmbtn--true {
  top: 16px;
  left: 100vw;
  right: 16px;
  bottom: 100vh;
}


.section {
  display: none;
  h2 {
    font-weight: 400;
    text-align: center;
  }
}



.s0--0,
.s1--1,
.s2--2,
.s3--3,
.s4--4,
.s5--5,
.s6--6,
.s7--7,
.s8--8 {
  display: block;
}


.core__nav {
  text-align: center;
}


.mmbtn {
  border-radius: 0px;
  filter: drop-shadow(4px 4px 0 white);
  transform: scale(1) translateX(0) translateY(0);
  img {
    width: 100%;
  }
  &:hover {
    color: $hoverColor;
    filter: drop-shadow(4px 4px 0 $hoverColor);
    border-color: $hoverColor;
    transform: scale(1) translateX(0) translateY(0);
  }
}
.nav {
  $nh: 96px;
  line-height: $nh;
  height: $nh;
  font-size: $nh/4;
  box-sizing: border-box;
  border: 1px solid white;
  background: black;
  filter: drop-shadow(4px 4px 0 white);
  margin-bottom: 12px;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(4px 4px 0 $hoverColor);
    border: 1px solid $hoverColor;
    color: $hoverColor;
  }
}


.listing.listing-state--0,
.listing-nav.listing-state--0,
.all-listings.listing-state--1 {
  display: none;
}


$lfs: 16px;
.listing {
  margin-top: 16px;
  border-top: 1px solid white;
  padding-top: 12px;
  text-align: center;
  
  &__index {
    font-size: $lfs;
  }
  &__id {
    font-size: $lfs;
    margin-bottom: 12px;
  }
  &__img {
    width: 100%;
    img {
      width: 100%;
      border: 1px solid white;
    }

    filter: drop-shadow(4px 4px 0 white);
    

  }
  &__price {
    margin-top: 12px;
    font-size: $lfs*2;
  }
}

.listing-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid white;
  button {
    width: 33%;
    box-sizing: border-box;
    margin:0;
    border: none;
    border-radius: 0;
    height: $h;
    background: black;
    &:hover {
      background: black;
      color: $hoverColor;
    }
  }
  .middle-btn {
    border-right: 1px solid white;
    border-left: 1px solid white;
  }

}

img.token {
  height: $lfs*2.5;
  vertical-align: middle;
  margin-left: -8px;
  margin-top: -4px;
}


.listing-link {
  display: inline-block;
  border: 1px solid white;
  width: 40%;
  margin: 8px;
  img {
    width: 100%;
  }
  background: black;
  filter: drop-shadow(4px 4px 0 white);
  cursor: pointer;

  &:hover {
    filter: drop-shadow(4px 4px 0 $hoverColor);
    border: 1px solid $hoverColor;
  }
}


.all-listings {
  border-top: 1px solid $accentColor;
  padding-top: 16px;
  overflow-y: scroll;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 16px;
  padding-bottom: 16px;
  top: $h*2;
 
}

.pending {
  display: none;
  &--true {
    display: block;
  }
  position: fixed;
  top: $h;
  bottom: 0;
  
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  background: black;
  z-index: 999;
  img {
    width: 100%;
  }
  p {
    font-size: 24px;
  }

  img + p {
    color: $accentColor;
  }
}


.lister-nav.lister-nav--0,
.lister.lister--1,
.stash.stash--2 {
  display: block;
}

.lister-nav,
.lister,
.stash {
  display: none;
}

.lister-nav {
  button {
    height: $h*2;
    display: block;
    width: 100%;
    margin: 16px 0;
    background: black;
    filter: drop-shadow(4px 4px 0 white);
    border-radius: 0;
    &:hover {
      background: black;
      color: $hoverColor;
      border: 1px solid $hoverColor;
      filter: drop-shadow(4px 4px 0 $hoverColor);
    }
  }

}

.lister {
  border-top: 1px solid white;
  margin-top: 16px;
  padding-top: 12px;
  p {
    font-size: 20px;
    input {
      background: black;
      border:none;
      border-bottom: 1px solid white;
      outline: none;
      color: $accentColor;
      width: 60px;
      text-align: center;
      font-size: 20px;
    }
    margin-bottom: 12px;
  }
  button {
    margin-top: 16px;
    width: 100%;
    height: 2*$h;
    background: black;
    filter: drop-shadow(4px 4px 0 white);
    border-radius: 0;
    &:hover {
      background: black;
      color: $hoverColor;
      border: 1px solid $hoverColor;
      filter: drop-shadow(4px 4px 0 $hoverColor);
    }
  }
}

.hide {
  display: none;
}

.item {
  width: 40%;
  box-sizing: border-box;
  margin: 16px;
  border: 1px solid white;
  position: relative;
  display: inline-block;
  img {
    width: 100%;
  }
  .info {
    position: absolute;
    bottom: 0;
    height: 20px;
    width: 100%;
    background: rgba(0,0,0,0.4);
    color: white;
    text-align: center;
  }
  filter: drop-shadow(4px 4px 0 white);
  background: black;

  cursor: pointer;

  &:hover {
    filter: drop-shadow(4px 4px 0 $hoverColor);
    border: 1px solid $hoverColor;
  }
}


.owned-familiars {
  position: fixed;
  bottom: $h;
  left: 0;
  top: 2.7*$h;
  background: black;
  border-top: 1px solid $accentColor;
  
}

.owned-familiars, .all-listings {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $accentColor;

  }


  &::-webkit-scrollbar-thumb {
    background: black;
    border-left: 1px solid $accentColor;
    border-right: 1px solid $accentColor;
  }

  &::-webkit-scrollbar-thumb:hover {
    
  }
}

.stash-nav {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  height: $h;
  border-top: 1px solid white;
  button {
    width: 50%;
    box-sizing: border-box;
    margin:0;
    border: none;
    border-radius: 0;
    height: $h;
    background: black;
    &:hover {
      background: black;
      color: $hoverColor;
    }
  }
  button + button {
    border-left: 1px solid white;
  }


 
}

.page {
  $ph: 32px;
  position: fixed;
  top: 2.7*$h - $ph; 
  right: 16px;
  height: $ph;
  line-height: $ph - 2px;
  background: $accentColor;
  color: black;
  width: 33%;
  //border-radius: $ph;
  border-radius: 0;
  text-align: center;
}


.core__relister, .core__delister {
   p {
    font-size: 20px;
    input {
      background: black;
      border:none;
      border-bottom: 1px solid white;
      outline: none;
      color: $orange;
      width: 60px;
      text-align: center;
      font-size: 20px;
    }
    margin-bottom: 12px;
  }

  button {
    margin-top: 16px;
    width: 100%;
    height: 2*$h;
    border-radius: 0;
    background: black;
    filter: drop-shadow(4px 4px 0 white);
    &:hover {
      background: black;
      color: $hoverColor;
      border: 1px solid $hoverColor;
      filter: drop-shadow(4px 4px 0 $hoverColor);
    }
  }
}


.core__spooky {
   button {
    margin-top: 16px;
    width: 100%;
    height: 2*$h;
  }
}

.listerOrStash {
  &-1, &-2 {
    color: $accentColor;
  }
  
}

h2, .header__text, button, p, input, div, button.mmbtn  {
  font-family: "Tiny5", sans-serif;
  
}

h2, button {
  text-transform: uppercase;
}

.all-listings {
  .page {
    width: 75%;
    top: 2*$h - 32px;
  }
}

.clicky {
  cursor: pointer;
}

h2.clicky:hover {
  color: $hoverColor;
}

.header:hover {
 .header__text {
  color: $hoverColor;
}
}



@media (orientation: portrait) {
  .listing__img img {
    height: 50vh;
    width: 50vh;
  }
}

// iphone SE
@media (max-width: 375px) and (max-height: 667px) {

  .listing__img img {
    height: 33vh;
    width: 33vh;
  }

  .lister-nav {
    button {
      height: 25vh;
    }
  }
  .nav {
    height: 72px;
    line-height: 72px;
  }
}

@media (max-width: 360px) and (max-height: 740px) {

  .listing__img img {
    height: 39vh;
    width: 39vh;
  }
}

@media (min-width: 412px) and (max-width: 412px) and (max-height: 915px) {
  .listing__img img {
    height: 35vh;
    width: 35vh;
  }
}

@media (min-width: 420px) and (max-width: 540px) and (max-height: 720px) {
  .listing__img img {
    height: 35vh;
    width: 35vh;
  }
}
@media (min-width: 430px) and (max-width: 430px) and (max-height: 932px) {
  .listing__img img {
    height: 35vh;
    width: 35vh;
  }
}
@media (min-width: 414px) and (max-width: 414px) and (max-height: 896px) {
  .listing__img img {
    height: 35vh;
    width: 35vh;
  }
}
@media (min-width: 390px) and (max-width: 390px) and (max-height: 844px) {
  .listing__img img {
    height: 35vh;
    width: 35vh;
  }
}

$bpw1: 820px;
$bph1: 1180px;

@media (min-width: 700px) and (min-height: 800px) and (max-width: $bpw1) and (max-height: $bph1) {
 
  .listing__img img {
    height: 50vh;
    width: 50vh;
  }
  h2 {
    font-size: 32px;

  }
  .listing {
    &__index,
    &__id {
      font-size: 24px;
    }
  }
}


$bpw2: 1024px;
$bph2: 1369px;
@media (min-width: $bpw1) and (min-height: $bph1) and (max-width: $bpw2) and (max-height: $bph2) {
 
 
  .listing__img img {
    height: 50vh;
    width: 50vh;
  }
  h2 {
    font-size: 48px;

  }
  .listing {
    &__index,
    &__id {
      font-size: 24px;
    }
  }
}

@media (orientation: landscape) {
  .listing__img img {
    height: 50vh;
    width: 50vh;
  }
  .listing-link {
    width: 23%;
  }
  .item {
    width: 22%
  }
  .mmbtn {
    img {
      height: 50vh;
      width: 50vh;
      display: block;
      margin: 0 auto;
    }
  }

  .all-listings .page {
    width: 240px;
  }

  .pending {
    img {
      height: 60vh;
      width: 60vh;
    }
  }


}



$hamH: $h/4;
.hamburger {
  position: fixed;
  right: ($h - $hamH)/2;
  top: ($h - $hamH)/2;
  display: inline-block;

  //border: 1px solid white;
  height: $hamH;
  width: $hamH;
  vertical-align: middle;
  margin-right: 8px; 
  box-sizing: border-box;
}

$barH: $hamH/6;
.bar {
  position: absolute;
  background: white;
  height: $barH;
  width: 100%;
  transform: rotateZ(0);
  &--top {
    top: 0;
    
  }

  &--middle {
    top: $hamH/2 - $barH/2;
  }

  &--bottom {
    bottom: 0;
  }


}

.header:hover {
  .bar {
    background: $hoverColor;

  }

}